.navbar .nav-content {
    height: 80px;
}

.navbar {
    width: 100%;
    height: 80px;
    position: absolute;
    z-index: 5;
}

.navbar .right,
.navbar .left {
    min-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar .right {
    flex-direction: row-reverse;
}

.navbar .right a {
    text-decoration: none;
    color: #6a6183;
}

.navbar .right .icon {
    width: 30px;
    height: 30px;
}

.navbar .middle,
.navbar .right,
.navbar .left {
    align-items: center;
}

.navbar .middle,
.navbar .left {
    display: flex;
    flex-direction: row;
}

.navbar .nav-item+.navbar .nav-item {}

.navbar .nav-item {
    color: black;
    font-weight: 400;
    font-size: 1.25rem;
    padding: 20px 20px;
}

.navbar .left {
    height: 100%;
}

.navbar .right Link {
    margin-right: 10px;
}

.nav-cta:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.navbar .left .logo {
    height: 70px;
    padding-top: 20px;
    cursor: pointer;
}

.middle a {
    padding: 0 20px;
    text-decoration: none;
}

.mobileNavbar img {
    height: 40px;
    cursor: pointer;
}

.mobileNavbarContent {
    padding: 25px;
}

.hamburgerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hamburgerItem {
    font-size: 30px;
    transition: 250ms ease-in-out;
    border-radius: 7px;
    cursor: pointer;
    padding: 10px 0;

}

.hamburgerItem:hover {
    background-color: black;
}

.hamburgerItem:hover .nav-item {
    color: white;
}

.hamburgerItem+.hamburgerItem {
    margin: 15px 0;
}

.hamburgerMiddle {
    text-align: center;
}

.hamburgerMiddle .nav-item {
    text-decoration: none;
}

.hamburgerTop .close {
}

.nav-content .first {
    border-radius: 30px;
    padding: 15px 25px;
    background-color: #fff;
    color: #d12828 !important;
    box-shadow: 0px 12px 30px -18px rgba(61, 61, 61, 0.75);
    -webkit-box-shadow: 0px 12px 30px -18px rgba(61, 61, 61, 0.75);
    -moz-box-shadow: 0px 12px 30px -18px rgba(61, 61, 61, 0.75);
}

.move {
    transform: translateX(350px);
}

.mobileNavbar {
    transition: 250ms ease-in-out;
    position: fixed;
    z-index: 26;
    top: 0;
    right: 0;
    bottom: 0;
    width: 350px;
    height: 100vh;
    background-color: white;
}

.mobileNavbarContent {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
}

.hamburgerBottom img {
    filter: invert();
}


.hamburgerBottom {
    text-align: right;
}

.nav-content {
    /* padding:30px 0; */
    display: flex;
    justify-content: space-between;
}

.nav-content .middle,
.nav-content .right {
    padding: 50px 0 30px 0;
}

.navbar .mobile {
    display: none;
}

.navbar .mobile img {
    width: 32px;
    height: auto;
    filter: invert();
    cursor: pointer;
}

@media only screen and (max-width: 1110px) {
    .navbar .desktop {
        display: none;
    }

    .navbar .mobile {
        display: block;
    }
}


.nav-content .logo {
    /* padding-top: 25px; */
}