@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body, #root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: #000000;
  font-family: 'Inter';
}

#root {
  background: url('../src/Assets/images/bg.png') no-repeat;
  /* background-attachment: fixed; */
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  min-height: 100%;
  height: max-content;
}

/* .App {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(315.61deg, #00C3A0 -14.63%, #1A2C8A 100%);
} */

.container {
  width:80%;
  max-width: 1440px;
  margin:0 auto;
}


svg {
  height:100px;
  width:100px;
}

.footer-content {
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.footer-content .right a {
  width:15px;
  height:15px;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin:0 5px;
  background-color: blue;
  border-radius: 50%;
}

.footer-content .right {
  display:flex;
}
.social-icon img{
  width:15px;
  height:15px;
  filter:invert();
 
  
  
}

.copyright {
  text-align: center;
  margin-top: 30px;
}

button {
  font-family: inherit;
}


/* .swal2-container {
  position: absolute;

} */

.swal2-container {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}