.landing-page {
    margin-top: 60px;
}
.main-text {
    font-size: 64px;
    letter-spacing: -2px;
}
.home-content {
    height:100%;
    padding: 8rem 0;
    position: relative;
}
.box-title {
    font-weight: bold;
}
.box {
    min-width: 420px;
    margin-top: 25px;
        background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.27 );
    backdrop-filter: blur( 11.5px );
    -webkit-backdrop-filter: blur( 11.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    display:flex;
    gap:30px;
    padding:15px;
    width:40%;
}
.box-column {
    margin-top: 40px;
}
.landing3d {
    position: absolute;
    right:86px;
    top:180px;
}
.box-img {
    display:flex;
    align-items: center;
    margin-left: 10px;
}
.box img {
    width:32px;
    display:block;
}
.features {
    margin:8rem auto;
}
.box-desc {
    margin-top: 8px;
    color:#191919;
}
.features-txt {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.support-text {
    width:80%;
    margin:2rem auto;
    font-size: clamp(40px,2.5vw,60px);
}
.workers {
    display:flex;
    justify-content: center;
}

.support-desc {
    color:#191919;
    max-width: 800px;
    line-height: 1.5;
    font-size: clamp(18px, 1.1vw, 36px);

    
}
.workers img {
    /* min-width: 100%; */
    max-width: 100%;
    width:750px;
    height:auto;

}
.first {
    background-color: #070450;
    border:0;
    color:#fff;
    font-family: inherit;
    cursor: pointer;
    padding:1rem 4rem;
    transition: 250ms ease-in-out;
    border-radius: 7px;
    font-size: 1.1rem;
    font-weight: 500;
}
.first:hover {
    background-color: #211e75;
}

.landingRow {
    display:flex;
    gap:60px;
}
.rowTitle {
    
    font-size: clamp(26px, 6vw, 70px);
    font-weight: 900;
    letter-spacing: -1px;
}
.landingRow > * {
    flex-grow: 1;
}
.landingRow img {
    border-radius: 7px;
    object-fit: contain;
    width:550px;
}
.landingRowLeft button {
    margin-top: 2rem;
    font-size: inherit;
}
.landingRowLeft {
    margin:auto;
}
.packages .rowTitle {
    text-align: center;
    padding-bottom: 6rem;
}
.rowDesc {
    font-size: clamp(18px, 1.1vw, 36px);
    margin-top: 2rem;
    color:#191919;
}
.landingRow .rowTitle {
    margin-top: 30px;
    font-size: clamp(24px, 3.5vw, 56px);
}
.package {
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}
.landingRow .rowDesc {
    line-height: 1.6;
}
.spacer {
    margin-top: 5rem;
}
.spacer-bottom {
    margin-bottom: 8rem;
}

.white-full {
    background-color: #ffffff;
}

.cta {
    position: relative;
}
.cta-title {
    text-align: center;
    font-size: clamp(40px,2.5vw,60px);
    font-weight: bold;
    margin:2rem 0;
}
.cta-row img {
    width: 175px;
    height:auto;
    min-width: 100%;
    justify-content:space-around;
}
.landing3d {
    width: 1000px;
    position: absolute;
    right: -153px;
    top: 90px;
}
.cta-row {
    display:flex;

    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 15px;
    padding:3rem 3rem;
    font-weight: bold;
    font-size: clamp(40px,2.5vw,60px);
    background: rgba( 255, 255, 255, 0.6);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.27 );
    backdrop-filter: blur( 11.5px );
    -webkit-backdrop-filter: blur( 11.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    flex-wrap: wrap;
}
.reverse {
    flex-direction: row-reverse;
    text-align: right;
}

.package {
    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.27 );
    backdrop-filter: blur( 11.5px );
    -webkit-backdrop-filter: blur( 11.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding:30px 80px;
    min-height: 500px;
}
.package-title {
    font-size:clamp(30px,2vw,70px);
    font-weight: bold;
}
.package-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    text-align: center;
    padding-bottom: 5rem;
}

.package-price {
    font-size: clamp(40px,2.5vw,60px);
    font-weight: bold;
    color: #5059c9;
    margin:2rem 0;
}

.package .data {
    padding-bottom: 20px;
    line-height: 2;
}

.package .number {
    font-size: 1.2rem;
}

.package.featured {
    transform: scale(1.15);
    z-index: 1;
    background: white;
}


/* media query phone */

@media only screen and (max-width: 1200px) {
    .package-row {
        flex-direction: column;
    }

    .package {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1110px) {
    .main-text {
        text-align: center;
    }
    .landingRow {
        flex-direction: column;
        align-items: center;
    }
    .landingRowRight {
        display: flex;
        justify-content: center;
    }
    .landingRow img {
        text-align: center;
        width:80%;
    }
    .box-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .landing3d {
        display: none;
    }
  }