.login-wrapper {
    margin-top: 80px;
    height: 900px;
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.loginContainer {
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: rgba( 255, 255, 255, 0.5 );
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 15%);
    backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); */

    height: max-content;
    /* max-width: 600px; */
}

.loginLeft {
    background: rgba( 255, 255, 255, 0.5 );
    box-shadow: 0 8px 32px 0 rgb(31 38 135 / 15%);
    backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    max-width: 600px;

}

.dont {
    color: black !important;
}

.loginLeft p {
    
   color:black;
}
.loginLeft {
    /* width:40%; */
    /* width: clamp(); */
    width: 100%;
    margin: auto 0;
}
.loginRight {
    width:60%;
    
}

.credentialTitle {
    color: rgb(65, 65, 65);
    font-weight: 500;
}

.credentialsBoxRegister div {
    flex-grow:1;
}
.credentialsBoxRegister {
    display: flex;
    gap:20px;
    width: 100%;
}
.loginContent {
    padding: clamp(40px, 4vw, 70px);
    width: 100%;
}
   
.loginRight img{
    min-height: 100%;
    display: block;
width: 100%;
max-width: 100%;
height: 700px;
object-fit: cover;
border-radius: 0 11px 11px 0;
}
#credentialsBreak {
    flex-direction: row;
}
.loginHead {
    font-size: clamp(24px, 2.6vw, 6rem);
    font-weight: 800;

}
.loginText .support {
    margin-top: 15px;
    color: #4f4f4f;
    font-size: 18px;
}
.loginText {
    margin:20px 0;
    
}
.loginLeft a {
    text-decoration: underline;
    color:#022957;
}
.loginLeft input {
    background-color: #d9d9d94d;
    outline:0;
    border: 1px solid #898F9B;
    border-radius: 5px;
    padding:10px 15px;
    width:100%;
    margin:15px 0;
    height: 50px;
    transition: 250ms ease-in-out;
    color:#022957;
    font-family: inherit;
    font-size: 1rem;
}

.loginLeft input:focus {
    /* border: 2px solid #022957; */

}
.loginLeft button {
    margin-top: 20px;
    cursor:pointer;
    width:100%;
    transition: 250ms ease-in-out;
    background-color: #4C70FF;
    font-family: inherit;
    font-weight: 700;
    font-size: 1.1rem;
    color: white;
    padding: 18px 0;
    border:0;
    border-radius: 5px;
}

.loginTwo {
    text-align: center;
    margin-top: 4rem;
    
}
.loginTwo p {
    color: #5f5e5e;
    font-weight: 400;
}
.googleBtn img{
    width:32px;
    height:32px;
}

.googleBtn {
    border-radius: 11px;
    border: 2px solid #f6f4f6;
    margin-top: 1rem;
    padding:15px;
    
    text-align: center;
}

@media only screen and (max-width: 1110px) {
    .loginRight {
        width: 0;
        display:none;
    }
    .loginLeft {
        width:100%;
    }
    .loginText, .dont{
        text-align: center;
    }
    #credentialsBreak {
     flex-direction: column;
    }
    .login-image {
        display: none;
      }

  }
  .loginLeft button:hover {
    background-color: #5b74dc;
  }

